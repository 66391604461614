import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class VentaService {
	async crear(datos) {
		return await fetchWrapper.post(`${ruta}/ventas`, datos);
	}

	async getAllVentas() {
		return await fetchWrapper.get(`${ruta}/ventas`);
	}

	async getVentasPaginate(parametros) {
		const queryParams = parametros
			? Object.keys(parametros)
				.map(
					(k) =>
						encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
				)
				.join("&")
			: "";
		return await fetchWrapper.get(`${ruta}/ventas/listado_ventas_paginate?` + queryParams);
	}

	async getVenta(id) {
		return await fetchWrapper.get(`${ruta}/ventas/` + id);
	}

	async imprimirVenta(id) {
		return await fetchWrapper.getPdf(`${ruta}/venta/imprimir/${id}`);
	}
	async imprimirVentaFactura(id) {
		return await fetchWrapper.getPdf(`${ruta}/factura/imprimir/${id}`);
	}
	async filtrarVentas(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_venta`, datos);
	}
	async imprimirPDF(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte/venta/imprimir`, datos);
	}

	async imprimirRFacturasPDF(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte_facturas`, datos);
	}

	async imprimirRGananciasPDF(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte_ganancias_ventas`, datos);
	}

	async exportarVentas(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/ventas-export`,
			datos
		);
		return exportado;
	}
	async exportarRFacturas(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/reporte-facturas-export`,
			datos
		);
		return exportado;
	}
	async exportarRGananciasVentas(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/ganancias_ventas_export`,
			datos
		);
		return exportado;
	}

	async filtrarGananciasVentas(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_ganancias_venta`, datos);
	}

	async anularVenta(id, datos) {
		return await fetchWrapper.put(`${ruta}/ventas/anular/${id}`, datos);
	}
	updateMetodosPago(datos) {
		return fetchWrapper.post(`${ruta}/ventas/metodo_pago`, datos);
	}

	/* ====================================
	RUTAS PARA FACTURACION ELECTRONICA 
	========================================*/

	async filtros_facturas(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_factura`, datos);
	}
	async exportarFacturas(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/facturas-export`,
			datos
		);
		return exportado;
	}
	async imprimirPDFFacturas(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte/factura/imprimir`, datos);
	}

	async exportarRFacturasSIN(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/reporte-facturas-sin-export`,
			datos
		);
		return exportado;
	}
	async VentaGeneral(datos) {
		return await fetchWrapper.post(`${ruta}/ventas/venta_datos_necesarios`, datos);
	}
	async imprimirVentaProforma(id) {
		return await fetchWrapper.getPdf(`${ruta}/venta/imprimir/proforma/${id}`);
	}
	async anularProforma(id) {
		return await fetchWrapper.delete(`${ruta}/proforma/anular/${id}`);
	}
	async getAllProformas() {
		return await fetchWrapper.get(`${ruta}/proformas`);
	}

	async motivo_anulacions() {
		return await fetchWrapper.get(`${ruta}/motivo_anulacions`);
	}
}
